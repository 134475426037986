<template>
  <div class="edit-payment-gateway">

    <div class="status-fields">

      <div class="edit-payment-gateway-field-box pb-2">
        <span>{{ $t('treasury.paymentGateways.labels.status') }}</span>
        <custom-select :default="paymentGateway.status.value"
                       :options="status"
                       v-model="paymentGateway.status.value"/>
      </div>

      <div class="edit-payment-gateway-field-box pb-2">
        <span>{{ $t('treasury.paymentGateways.labels.type') }}</span>
        <custom-select :default="paymentGateway.type.value"
                       :options="types"
                       v-model="paymentGateway.type.value"/>
      </div>

      <div v-if="paymentGateway.type.value.value === 1" class="edit-payment-gateway-field-box">
        <span>{{ $t('treasury.paymentGateways.labels.provider') }}</span>
        <custom-select :default="paymentGateway.gatewayProvider.value"
                       :options="paymentGatewayProviders"
                       v-model="paymentGateway.gatewayProvider.value"/>
      </div>

      <div v-if="checkUserPermissions('bank.show')" class="edit-payment-gateway-field-box">
        <span>{{ $t('treasury.paymentGateways.labels.bank') }}</span>
        <custom-select :default="paymentGateway.bank.value"
                       :options="banks"
                       v-model="paymentGateway.bank.value"/>
      </div>

    </div>

    <div class="general-fields">

      <div class="edit-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.title')}`"
                               align="right"
                               :regex="titleRegex"
                               :classes="{'w-full': true}"
                               v-model="paymentGateway.title"/>
      </div>

      <div class="edit-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.endTime')}`"
                               :rtl="false"
                               :classes="{'w-full': true, 'rtl': true}"
                               :regex="endTimeRegex"
                               v-model="paymentGateway.endTime"/>
      </div>

      <div class="edit-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.merchantCode')}`"
                             :rtl="false"
                             :classes="{'w-full': true, 'rtl': true}"
                             :is-empty="false"
                             v-model="paymentGateway.merchantCode"/>
      </div>

      <div v-if="paymentGateway.gatewayProvider.value.hasTerminalCode || paymentGateway.type.value.value !== 1" class="edit-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.terminalCode')}`"
                             :rtl="false"
                             :classes="{'w-full': true, 'rtl': true}"
                             :is-empty="false"
                             v-model="paymentGateway.terminalCode"/>
      </div>

      <div class="edit-payment-gateway-field-box">
        <custom-validate-input :label="`${$t('treasury.paymentGateways.labels.description')}`"
                               align="right"
                               :classes="{'w-full': true}"
                               :is-empty="true"
                               v-model="paymentGateway.description"/>

      </div>

      <vs-button id="deleteBankBTN"
                 v-if="checkUserPermissions('payment_gateway.delete')"
                 class="w-full mt-5"
                 color="danger"
                 @click="$refs.deleteConfirmation.showDialog()">{{ $t('treasury.paymentGateways.labels.delete') }}</vs-button>

      <custom-dialog ref="deleteConfirmation"
                     :title="$t('treasury.paymentGateways.confirmations.delete.title')"
                     :body="$t('treasury.paymentGateways.confirmations.delete.body1', {paymentGateway: paymentGateway.title.value})"
                     @accept="deletePaymentGateway"
                     @close=""/>
    </div>

    <button v-show="false"
            id="editPaymentGatewaysBTN"
            @click="editPaymentGateway"/>
  </div>
</template>

<script>
import axios from 'axios'
import {
  editPaymentGateways,
  getPaymentGateway,
  deletePaymentGateways,
  getPaymentGatewayProviders
} from '../../../../../../http/requests/paymentGateways'
import CustomValidateInput from '../../../../../../components/customInput/customValidateInput'
import CustomSelect from '../../../../../../components/customSelect/customSelect'
import {getBanks} from '../../../../../../http/requests/banks'
import CustomNumberInput from "../../../../../../components/customInput/customNumberInput";
import CustomDialog from "../../../../../../components/customDialog/customDialog";
import {checkUserPermissions} from "../../../../../../assets/js/functions";

export default {
  name: 'editPaymentGateway',
  components: {CustomDialog, CustomNumberInput, CustomSelect, CustomValidateInput},
  props: {
    paymentGatewayId: 0
  },
  data () {
    return {
      titleRegex: this.$validator('regex.treasury.paymentGateway.name'),
      endTimeRegex: this.$validator('regex.time'),
      paymentGateway: {
        status: {
          value: {
            label: this.$t('treasury.paymentGateways.status.enable'),
            value: 1
          },
          isValid: false
        },
        title: {
          value: '',
          isValid: false
        },
        merchantCode: {
          value: '',
          isValid: false
        },
        terminalCode: {
          value: '',
          isValid: false
        },
        bank: {
          value: {
            label: '-',
            value: 1
          },
          isValid: false
        },
        endTime: {
          value: '',
          isValid: false
        },
        description: {
          value: '',
          isValid: false
        },
        type: {
          value: {
            label: '-',
            value: '-'
          },
          isValid: false
        },
        gatewayProvider: {
          value: {
            label: '-',
            value: 0,
            hasTerminalCode: true
          },
          isValid: false
        }
      },
      paymentGatewayProviders: [],
      status: [
        {
          label: this.$t('treasury.paymentGateways.status.enable'),
          value: 1
        },
        {
          label: this.$t('treasury.paymentGateways.status.disable'),
          value: 0
        }
      ],
      types: [
        {
          label: this.$t('treasury.paymentGateways.types.online'),
          value: 1
        },
        {
          label: this.$t('treasury.paymentGateways.types.pos'),
          value: 2
        }
      ],
      banks: [],
      actions: [
        {
          toolbar: [
            {
              id: 'editPaymentGatewaysBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'paymentGateways'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    /*setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)*/

    this.getPaymentGateway()

    if (checkUserPermissions('bank.show')) {
      this.getBanks()
    }

    this.getPaymentGatewayProviders()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getPaymentGatewayProviders () {
      getPaymentGatewayProviders().then((response) => {
        const gateways = response.data.data
        gateways.forEach((item) => {
          this.paymentGatewayProviders.push({
            label: item.name ? item.name : '',
            value: item.id
          })
        })
      })
    },
    getBanks () {
      getBanks().then(response => {
        const banks = response.data.data
        banks.forEach((bank) => {
          this.banks.push({
            value: bank.id,
            label: bank.name
          })
        })
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    getPaymentGateway () {
      getPaymentGateway(this.paymentGatewayId).then(response => {
        const paymentGateway = response.data.data
        this.paymentGateway = {
          status: {
            value: {
              label: paymentGateway.enabled ? this.$t('treasury.paymentGateways.status.enable') : this.$t('treasury.paymentGateways.status.disable'),
              value: paymentGateway.enabled ? 1 : 0
            },
            isValid: true
          },
          title: {
            value: paymentGateway.title,
            isValid: this.titleRegex.test(paymentGateway.title)
          },
          merchantCode: {
            value: paymentGateway.merchant_code,
            isValid: true
          },
          terminalCode: {
            value: paymentGateway.terminal_code,
            isValid: true
          },
          bank: {
            value: {
              label: paymentGateway.bank.name,
              value: paymentGateway.bank.id
            },
            isValid: true
          },
          endTime: {
            value: paymentGateway.end_time,
            isValid: this.endTimeRegex.test(paymentGateway.end_time)
          },
          description: {
            value: paymentGateway.description || '',
            isValid: true
          },
          type: {
            value: this.types[paymentGateway.type - 1],
            isValid: false
          },
          gatewayProvider: {
            value: {
              label: paymentGateway.provider ? paymentGateway.provider.name : '-',
              value: paymentGateway.provider ? paymentGateway.provider.id : 0,
              hasTerminalCode: paymentGateway.provider ? paymentGateway.provider.has_terminal_code === 1 : true
            },
            isValid: true
          },
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    editPaymentGateway () {
      if (this.paymentGateway.bank.value.value === 0 ||
        (this.paymentGateway.gatewayProvider.value.value === 0 && this.paymentGateway.type.value.value === 1) ||
        !this.paymentGateway.title.isValid ||
        !this.paymentGateway.merchantCode.isValid ||
        (!this.paymentGateway.terminalCode.isValid && this.paymentGateway.gatewayProvider.value.hasTerminalCode) ||
        !this.paymentGateway.endTime.isValid) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('treasury.paymentGateways.notifications.incorrectData'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      const paymentGateway = {
        title: this.paymentGateway.title.value,
        merchant_code: this.paymentGateway.merchantCode.value,
        terminal_code: this.paymentGateway.terminalCode.value,
        bank_id: this.paymentGateway.bank.value.value,
        type: this.paymentGateway.type.value.value,
        provider_id: this.paymentGateway.gatewayProvider.value.value,
        enabled: this.paymentGateway.status.value.value,
        description: this.paymentGateway.description.value,
        end_time: `${this.paymentGateway.endTime.value}`
      }
      editPaymentGateways(this.paymentGatewayId, paymentGateway).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.paymentGateways.notifications.edit.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changePaymentGateway')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.notifications.edit.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    deletePaymentGateway () {
      deletePaymentGateways(this.paymentGatewayId).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.paymentGateways.notifications.delete.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$store.dispatch('helper/changePaymentGateway')
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.paymentGateways.notifications.delete.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">
.edit-payment-gateway {
  padding: 0 !important;

  .status-fields, .general-fields {
    padding: 1.25rem;
  }

  .status-fields {
    border-radius: .5rem;
    background: #cecece22;
    border: 1px solid #cecece;
  }

  .edit-payment-gateway-field-box {
    width: 100%;
    margin-top: 10px;
    position: relative;

    span {
      position: absolute;
      z-index: 300;
      font-size: 12px;
      top: -10px;
      left: 7px;
      backdrop-filter: sepia(1);
      background: linear-gradient(180deg, #f8f8f8, #ffffff);
      padding: 0 5px;

      &.invalid-text-helper {
        top: unset !important;
        background-color: inherit;
      }
    }

    .useral-custom-element-select {

      .selected {
        font-size: 13px;
        line-height: 35px;
        height: 35px;
      }

      .items {
        font-size: 13px;

        div {
          line-height: 35px;
          height: 35px;
        }
      }
    }
  }

  .general-fields {
    .edit-payment-gateway-field-box {
      margin-bottom: 35px;
    }
  }
}

/*.vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 0 !important;
}*/
</style>
